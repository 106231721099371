@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@200;700&display=swap');

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  text-decoration: none;
}

:root {
  --variable-color: rgb(9, 118, 108);
}

body {
  font-family: 'Noto Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
